<script setup lang="ts">
import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";
import { lightOrDark } from "@/utils";

const { assistantSettings } = useAssistantSettingsStore();

const logoUrl = computed(() => {
  return lightOrDark(assistantSettings.theme.auth_body_background_color) ===
    "dark"
    ? assistantSettings.images.logo_light
    : assistantSettings.images.logo_dark;
});
</script>

<template>
  <header class="auth-header">
    <div class="logo" @click="navigateTo('/welcome')">
      <!-- <img src="~/assets/images/logo-auth.png" /> -->
      <img :src="logoUrl" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.auth-header {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-end;
  padding: 40px 0 10px;
}

.logo {
  img {
    width: 200px;
  }
}
</style>
