<script setup>
useHead({
  htmlAttrs: {
    class: "layout-auth",
  },
});
</script>

<template>
  <div class="auth">
    <div class="inner">
      <AuthHeader></AuthHeader>
      <AuthBody>
        <slot />
      </AuthBody>
      <AuthFooter></AuthFooter>
    </div>
  </div>
</template>

<style lang="scss">
html.layout-auth {
  background-color: var(--auth-body-background-color);
  color: var(--auth-body-text-color);
}

html.layout-auth a {
  color: var(--auth-body-text-color);
  text-decoration: none;
}
</style>

<style lang="scss" scoped>
.auth {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
  gap: 1rem;
  text-align: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.inner {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90vw;
  max-width: 400px;
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
</style>
